@import 'inter-ui/inter.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

// @import '@angular/material/_theming';

@import 'assets/primeng/primeng-theme.css';
@import 'primeng/resources/primeng.css';

@import 'quill/dist/quill.core.css';
@import 'quill/dist/quill.bubble.css';
@import 'quill/dist/quill.snow.css';

@import './assets/material-angular.css';
@import './assets/material-button.css';
@import './assets/material-dropdown.css';
@import './assets/material-input.css';
@import './assets/material-list.css';
@import './assets/material-popup.css';

@font-face {
	font-display: swap;
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	src:
		url('./assets/fonts/roboto-v30-latin-regular.woff2') format('woff2'),
		url('./assets/fonts/roboto-v30-latin-regular.woff') format('woff');
}

@font-face {
	font-display: swap;
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 700;
	src:
		url('./assets/fonts/roboto-v30-latin-700.woff2') format('woff2'),
		url('./assets/fonts/roboto-v30-latin-700.woff') format('woff');
}

@font-face {
	font-display: swap;
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 300;
	src:
		url('./assets/fonts/roboto-v30-latin-300.woff2') format('woff2'),
		url('./assets/fonts/roboto-v30-latin-300.woff') format('woff');
}

@font-face {
	font-display: swap;
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 100;
	src:
		url('./assets/fonts/roboto-v30-latin-100.woff2') format('woff2'),
		url('./assets/fonts/roboto-v30-latin-100.woff') format('woff');
}

html {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
	text-rendering: optimizeLegibility;
	font-family: 'Inter', 'system-ui', sans-serif;
}

@supports (font-variation-settings: normal) {
	html {
		font-family: 'Inter var', 'system-ui', sans-serif;
	}
}

quill-editor {
	display: block !important;
	width: 100% !important;

	.ql-font-roboto-bold {
		font-family: 'Roboto', sans-serif;
		font-weight: 700;
	}

	.ql-font-roboto-light {
		font-family: 'Roboto', sans-serif;
		font-weight: 200;
	}
}

[quill-editor-toolbar] .ql-font span[data-label='Roboto']::before {
	font-family: 'Roboto';
	font-weight: 400;
}

[quill-editor-toolbar] .ql-font span[data-label='Roboto Thin']::before {
	font-family: 'Roboto';
	font-weight: 100;
}

[quill-editor-toolbar] .ql-font span[data-label='Roboto Light']::before {
	font-family: 'Roboto';
	font-weight: 300;
}

[quill-editor-toolbar] .ql-font span[data-label='Roboto Bold']::before {
	font-family: 'Roboto';
	font-weight: 700;
}

.e-multiselect {
	border: 0 !important;

	&:after,
	&:before {
		display: none !important;
		background-color: transparent !important;
	}
}

.e-multi-select-wrapper {
	font-size: 16px;
	color: #505763 !important;
	padding: 0.75rem 1rem !important;
	margin-bottom: 0.75rem !important;
	line-height: 1.25 !important;
	border-width: 1px !important;
	border-radius: 0.5rem !important;
	border-color: #f9f9f9 !important;
	background-color: #f9f9f9 !important;
}

.e-dropdownbase {
	font-size: 16px !important;
	color: #cccccc !important;
}

.mat-column-company,
.mat-column-email {
	max-width: 200px;
	padding-right: 1rem !important;
}

.accordion {
	width: 100%;

	.accordion__toggle {
		cursor: pointer;
	}

	.accordion__content {
		overflow-x: hidden;
		overflow-y: hidden;
		max-height: 0;
		transition: max-height 255ms ease-in-out;
	}

	.accordion__toggle__icon {
		pointer-events: none;
		transform: rotate(0);
		transition: transform 255ms ease-in-out;
	}

	span {
		pointer-events: none;
	}
}

.accordion--open {
	.accordion__content {
		overflow-x: hidden;
		overflow-y: auto;
		max-height: 500px;
		transition: max-height 255ms ease-in-out;
	}

	.accordion__toggle__icon {
		transform: rotate(180deg);
		transition: transform 255ms ease-in-out;
	}
}

.wysiwyg {
	p,
	a,
	ul,
	ol {
		font-size: 0.875rem;
		color: rgba(41, 48, 59, 1);
	}

	a {
		text-decoration: underline;
	}

	ul {
		list-style: disc;
		padding-left: 1rem;
	}

	ol {
		list-style: none;
		counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
		padding-left: 0;

		li {
			counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
			counter-increment: list-0;
		}

		li:before {
			content: counter(list-0, decimal) '. ';
		}

		li.ql-indent-1 {
			padding-left: 1.5rem;
			counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
			counter-increment: list-1;
		}

		li.ql-indent-1:before {
			content: counter(list-1, decimal) '. ';
		}

		li.ql-indent-2 {
			padding-left: 2.5rem;
			counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;
			counter-increment: list-2;
		}

		li.ql-indent-2:before {
			content: counter(list-2, decimal) '. ';
		}

		li.ql-indent-3 {
			padding-left: 3.5rem;
			counter-reset: list-4 list-5 list-6 list-7 list-8 list-9;
			counter-increment: list-3;
		}

		li.ql-indent-3:before {
			content: counter(list-3, decimal) '. ';
		}

		li.ql-indent-4 {
			padding-left: 4.5rem;
			counter-reset: list-5 list-6 list-7 list-8 list-9;
			counter-increment: list-4;
		}

		li.ql-indent-4:before {
			content: counter(list-4, decimal) '. ';
		}
	}
}

.bg-image-login {
	background-image: url('assets/images/background.png');
}
$default-color: #ffffff;
$base-color: #29303b;
$btn-background: #fff;
$btn-border: #ccc;
$btn-text-color: #333;
$btn-arrow: #333;

$token-background: $base-color;
$token-text-color: #fff;
$token-remove-color: #fff;

$box-shadow-color: #959595;
$list-hover-background: #f5f5f5;
$label-color: #000;
$selected-background: #e9f4ff;

.mat-toolbar {
	background: $default-color;
}
.c-btn {
	padding-left: 1rem !important;
	padding-right: 1rem !important;
	padding-top: 0.75rem !important;
	padding-bottom: 0.75rem !important;
	background: #f9f9f9;
	border: 1px solid #f9f9f9;
	color: $btn-text-color;

	span {
		font-size: 16px !important;
		color: #cccccc !important;

		span {
			color: $token-background !important;
		}
	}
}
.selected-list {
	.c-list {
		.c-token {
			background: $token-background;
			border-radius: 0.5rem !important;
			.c-label {
				color: $token-text-color;
			}
			.c-remove {
				svg {
					fill: $token-remove-color;
				}
			}
		}
	}
	.c-angle-down,
	.c-angle-up {
		svg {
			fill: $btn-arrow;
		}
	}
}
.dropdown-list {
	ul {
		li:hover {
			background: $list-hover-background;
		}
	}
}
.arrow-up,
.arrow-down {
	border-bottom: 15px solid #fff;
}

.arrow-2 {
	border-bottom: 15px solid #ccc;
}
.list-area {
	border: 1px solid #ccc;
	background: #fff;
	box-shadow: 0px 1px 5px $box-shadow-color;
}
.select-all {
	border-bottom: 1px solid #ccc;
}
.list-filter {
	border-bottom: 1px solid #ccc;
	.c-search {
		svg {
			fill: #888;
		}
	}
	.c-clear {
		svg {
			fill: #888;
		}
	}
}

.pure-checkbox {
	input[type='checkbox']:focus + label:before,
	input[type='checkbox']:hover + label:before {
		border-color: $base-color;
		background-color: #f2f2f2;
	}
	input[type='checkbox'] + label {
		color: $label-color;
	}
	input[type='checkbox'] + label:before {
		color: $base-color;
		border: 1px solid $base-color;
	}
	input[type='checkbox'] + label:after {
		background-color: $base-color;
	}
	input[type='checkbox']:disabled + label:before {
		border-color: #cccccc;
	}
	input[type='checkbox']:disabled:checked + label:before {
		background-color: #cccccc;
	}
	input[type='checkbox'] + label:after {
		border-color: #ffffff;
	}
	input[type='radio']:checked + label:before {
		background-color: white;
	}
	input[type='checkbox']:checked + label:before {
		background: $base-color;
	}
}
.single-select-mode .pure-checkbox {
	input[type='checkbox']:focus + label:before,
	input[type='checkbox']:hover + label:before {
		border-color: $base-color;
		background-color: #f2f2f2;
	}
	input[type='checkbox'] + label {
		color: $label-color;
	}
	input[type='checkbox'] + label:before {
		color: transparent !important;
		border: 0px solid $base-color;
	}
	input[type='checkbox'] + label:after {
		background-color: transparent !important;
	}
	input[type='checkbox']:disabled + label:before {
		border-color: #cccccc;
	}
	input[type='checkbox']:disabled:checked + label:before {
		background-color: #cccccc;
	}
	input[type='checkbox'] + label:after {
		border-color: $base-color;
	}
	input[type='radio']:checked + label:before {
		background-color: white;
	}
	input[type='checkbox']:checked + label:before {
		background: none !important;
	}
}
.selected-item {
	background: $selected-background;
}
.btn-iceblue {
	background: $base-color;
	border: 1px solid $btn-border;
	color: #fff;
}

.popup-listing {
	.pure-checkbox {
		label {
			font-size: 14px;
			line-height: 1;
			padding-left: 0 !important;
		}
	}
	.pure-checkbox.selected-item {
		label {
			padding-left: 2em !important;
		}
	}
}

.single-select-mode {
	.pure-checkbox {
		label {
			padding-left: 0 !important;
		}
	}
	.pure-checkbox.selected-item {
		label {
			padding-left: 2em !important;
		}
	}
}

.list-area {
	.list-filter .c-input {
		color: $token-background !important;
		::-webkit-input-placeholder {
			color: $token-background !important;
		}
		:-moz-placeholder {
			color: $token-background !important;
			opacity: 1;
		}
		::-moz-placeholder {
			color: $token-background !important;
			opacity: 1;
		}
		:-ms-input-placeholder {
			color: $token-background !important;
		}
		::-ms-input-placeholder {
			color: $token-background !important;
		}
		::placeholder {
			color: $token-background !important;
		}
	}
}

.animate-spinner {
	animation: spin 4s linear infinite;
	transform-origin: center;
}

@keyframes spin {
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

// dropdown
.c-remove.clear-all {
	display: none !important;
	pointer-events: none !important;
}

// Fullscreen dialog
.full-screen-dialog {
	max-width: 100vw !important;
	box-sizing: border-box;
	overflow: hidden;
	width: 100% !important;
}

.full-screen-dialog .mat-dialog-container {
	border-radius: 0;
	width: 100vw;
	height: 100vh;
	min-height: 100vh;
	margin: 0;
	padding: 0;
	overflow: hidden;
	background-color: rgba(0, 0, 0, 0.1);
}

.settings-mat-menu {
	background: #282e36 !important;
	.mat-menu-content {
		padding-top: 0 !important;
		padding-bottom: 0 !important;
	}
}

.mat-dialog-container,
.c-btn {
	border-radius: 0.5rem !important;
}

.ql-container {
	border-bottom-left-radius: 0.5rem !important;
	border-bottom-right-radius: 0.5rem !important;
}

.mat-dialog-container {
	box-shadow:
		0 20px 25px -5px rgba(0, 0, 0, 0.1),
		0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
	padding: 0 !important;
}

.bg-transient {
	background:
		left bottom no-repeat,
		linear-gradient(220deg, rgb(43, 50, 66), rgb(14, 20, 20));
}

.terminal-dialog {
	.mat-dialog-container {
		box-shadow:
			0 20px 25px -5px rgba(0, 0, 0, 0.1),
			0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
		border-radius: 0.5rem !important;
		background-color: #29303b !important;
		padding: 0 !important;
	}
}

.mat-snack-bar-container {
	font-family:
		Inter,
		system-ui,
		-apple-system,
		BlinkMacSystemFont,
		'Segoe UI',
		Roboto,
		'Helvetica Neue',
		Arial,
		'Noto Sans',
		sans-serif,
		'Apple Color Emoji',
		'Segoe UI Emoji',
		'Segoe UI Symbol',
		'Noto Color Emoji' !important;
	background-color: rgba(17, 24, 39, 0.75) !important;
	color: #fff !important;
	font-size: 0.875rem !important;
	border-radius: 0.5rem !important;

	.mat-simple-snackbar-action button {
		background-color: #33a8e0;
		border-radius: 0.5rem !important;
		font-size: 0.75rem !important;
	}
}

.mat-tooltip {
	background-color: rgba(17, 24, 39, 0.75) !important;
	font-size: 0.875rem !important;
	text-align: center !important;
	line-height: 1.4 !important;
}

.cover__text {
	p {
		hyphens: auto;
	}

	.ql-font-roboto-bold {
		font-weight: 700;
	}

	.ql-font-roboto-light {
		font-weight: 300;
	}

	.ql-font-roboto-thin {
		font-weight: 100;
	}

	.ql-font-roboto {
		font-weight: 400;
	}
}

.wysiwyg--markdown {
	p,
	ul,
	ol {
		margin-bottom: 1rem;
	}
}

.bg-red-300 {
	background-color: #fccdcd;
}

.bg-green-300 {
	background-color: #defceb;
}

body.versioning-banner-open {
	// overflow: hidden;
}

.md\:w-editor-sidebar {
	@media (min-width: 768px) {
		width: 240px;
	}
}

.scroll-padding {
	@media (min-width: 768px) {
		scroll-padding-top: 5rem;
		top: 5rem;
		position: sticky;
	}
}
